import React from 'react';

import Seo from '../../components/seo/Seo';
import EasePhoneView from '../../views/EasePhoneView';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function EasePhone() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.services[1].title}
        currentDescription={siteNavigation.services[1].description}
      />
      <EasePhoneView />
    </>
  );
};