import React from 'react';

import {
  Security,
  FactCheckRounded,
  CloudDoneRounded,
  CompareArrowsRounded
} from '@mui/icons-material';

import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { ServiceTemplate } from '../components/templates/Service';

export default function EasePhoneView() {
  const { siteNavigation } = useSiteMetadata();

  const ref = siteNavigation.services[1].ref;
  const name = siteNavigation.services[1].title;

  const data = {
    bannerImages: [{
      filename: `${ref}1.jpg`,
      alt: '"PBX" written in a cloud pointing multiple pictogram devices',
    }, {
      filename: `${ref}2.jpg`,
      alt: 'A view of a woman with headphone in a call center office',
    }, {
      filename: `${ref}3.jpg`,
      alt: 'A view of 3 persons with headphone in a call center office',
    }],
    section1: {
      title: `${name}, chef d'orchestre de votre téléphonie`,
      titleImage: {
        filename: `${ref}_titleImage1.png`,
        alt: `Multiple responsive devices showing "${name}" on their screen`,
      },
      subtitle1: `${name} c'est quoi ?`,
      slogan1: 'L\'assurance d\'une téléphonie sécurisée et autonome.',
      content1: `Quel que soit la taille de votre entreprise, la téléphonie est un point stratégique dans votre société. Nous vous proposons un central téléphonique PBX cloud, le ${name}. Grâce aux technologies utilisées, nous vous garantissons la redondance de vos communications. Vous ne pourrez plus manquer un appel.`,
      imagesContent: [{
        filename: `${ref}_imageContent1.jpg`,
        alt: 'An old data server',
        // alt: 'A 1960 press call center in New York',
      }, {
        filename: `${ref}_imageContent2.jpg`,
        alt: 'A 1970 police call center in Brierley Hill - Flickr uploaded by Pigsonthewing - License: https://creativecommons.org/licenses/by-sa/2.0/deed.en ',
      }, {
        filename: `${ref}_imageContent3.jpg`,
        alt: 'A high tech data servers hallway',
        // alt: 'A 2006 very large call center in Lakeland',
      }, {
        filename: `${ref}_imageContent4.jpg`,
        alt: '2 persons with headphone in a 2020 call center',
      }],
      subtitle2: `Pourquoi utiliser ${name} ?`,
      slogan2: 'Simplifier et améliorer les échanges.',
      content2: `${name} est un PBX (central téléphonique) qui se paramètre facilement via une interface simple d'utilisation. Vous bénéficiez ainsi d'une autonomie de programmation qui vous évite l'intervention d'un prestataire externe. Utiliser le ${name}, c'est aussi bénéficier d'un système de redondances qui garantit une haute disponibilité. Le ${name} en bon Maestro, se couple également avec votre logiciel informatique.`,
    },
    section2: {
      title: `${name}, à vous de choisir la partition`,
      titleImage: {
        filename: `${ref}_titleImage2.png`,
        alt: `"${name}" written in a cloud pointing multiple pictogram devices`,
      },
      subtitle1: `Pourquoi choisir ${name} ?`,
      slogan1: 'Consommer juste et adapté.',
      content1: `Avec ${name}, vous ne payez que ce que vous utilisez. Nous vous proposons des tarifs qui s'adaptent à votre consommation réelle. Pas de surtaxe ou de forfait approximatif, nous réadaptons régulièrement notre offre afin de répondre à vos besoins.`,
    },
    advantages: {
      list: [{
        icon: <FactCheckRounded fontSize="large" />,
        content: 'Données accessibles à tous moments',
      }, {
        icon: <Security fontSize="large" />,
        content: 'Sécurisé',
      }, {
        icon: <CompareArrowsRounded fontSize="large" />,
        content: 'Adaptable',
      }, {
        icon: <CloudDoneRounded fontSize="large" />,
        content: 'Aucune perte de données',
      }],
      isInfo: false,
      info: '',
    },
    pricing: {
      isPricing: false,
      subtitle: '',
      info: '',
      subscriptions: [{
        title: '',
        price: '',
        services: [],
      }],
    },
    servicesPage: {
      prev: {
        title: siteNavigation.services[0].title,
        url: siteNavigation.services[0].url,
      },
      current: {
        title: name,
      },
      next: {
        title: siteNavigation.services[2].title,
        url: siteNavigation.services[2].url,
      },
    },
  };

  return (
    <ServiceTemplate data={data} />
  );
};